import { InvoiceV2, PaymentMethod } from '@/buyers/_gen/gql'
import A from '@/gf/components/A'
import useConfig from '@/gf/hooks/useConfig'
import { DownloadIcon } from '@heroicons/react/solid'
import { useOrderContext } from '../context'

const DownloadInvoiceButton = ({ invoice }: { invoice: InvoiceV2 }) => {
  const { storeOrder } = useOrderContext()

  const { buyersUrl } = useConfig()

  if (!invoice) return null

  if (storeOrder.paymentMethod === PaymentMethod.Direct && !invoice.link) return null

  const href =
    storeOrder.paymentMethod === PaymentMethod.Direct
      ? (invoice.link ?? '')
      : `${buyersUrl}/pdf/download/invoice/${invoice.id}`

  return (
    <A.S href={href} target="_blank" className="flex gap-2 items-center justify-center">
      <DownloadIcon className="h-5 w-5" />
      <span>Download Invoice</span>
    </A.S>
  )
}

export default DownloadInvoiceButton
