import { uniq } from 'lodash'
import { DateTime } from 'luxon'

import { Maybe, RequestForQuote, StoreOrder, User } from '@/buyers/_gen/gql'

import Reporting from '@/buyers/modules/Reporting'
import Id from '@/gf/modules/Id'
import StoreOrderM from '@/gf/modules/StoreOrder'

import Link from '@/gf/components/Link'
import { Column } from '@/gf/components/Reports/ReportingTable'

const getColumnRequestLink = ({
  requestForQuote,
}: {
  requestForQuote: Pick<RequestForQuote, 'id'> & {
    reportingStoreOrders: Pick<StoreOrder, 'state' | 'purchaseOrder'>[]
  }
}) => {
  const purchaseOrders = requestForQuote.reportingStoreOrders
    .filter(StoreOrderM.isQuoteApproved)
    .map(({ purchaseOrder }) => purchaseOrder)
    .filter(Reporting.isNotNullable)
  return purchaseOrders.length > 0 ? (
    <div className="flex flex-col items-start leading-snug">
      {purchaseOrders.map((purchaseOrder) => (
        <Link.T
          key={purchaseOrder}
          className="text-sm"
          to={`/rfqs/${requestForQuote.id}`}
          target="_blank"
        >
          {`PO #${purchaseOrder}`}
        </Link.T>
      ))}
      <span className="text-xs text-gray-500">Request {Id.shorten(requestForQuote.id)}</span>
    </div>
  ) : (
    <Link.T className="text-sm" to={`/rfqs/${requestForQuote.id}`} target="_blank">
      Request {Id.shorten(requestForQuote.id)}
    </Link.T>
  )
}

const getColumnRequestVendors =
  (useProcessedStoreOrders = true) =>
  ({
    requestForQuote,
  }: {
    requestForQuote: {
      reportingStoreOrders: (Pick<StoreOrder, 'state'> & { storeName: string })[]
    }
  }) => {
    const storeOrders = useProcessedStoreOrders
      ? requestForQuote.reportingStoreOrders.filter(StoreOrderM.isQuoteApproved)
      : requestForQuote.reportingStoreOrders
    return (
      <div className="flex flex-col">
        {uniq(
          (storeOrders.length > 0 ? storeOrders : storeOrders).map(({ storeName }) => storeName)
        ).map((name) => (
          <span key={name}>{name}</span>
        ))}
      </div>
    )
  }

const getRequestColumn = <
  T extends {
    requestForQuote: Pick<RequestForQuote, 'id'> & {
      reportingStoreOrders: Pick<StoreOrder, 'state' | 'purchaseOrder'>[]
    }
  },
>(): Column<T> => ({
  header: 'Request',
  getValue: getColumnRequestLink,
})

const getCreatedColumn = <
  T extends { requestForQuote: Pick<RequestForQuote, 'insertedAt'> },
>(): Column<T> => ({
  header: 'Created',
  getValue: ({ requestForQuote }) =>
    requestForQuote.insertedAt.toLocaleString(DateTime.DATETIME_MED),
  sortByField: 'requestForQuote.insertedAt',
})

const getCreatorColumn = <
  T extends { requestForQuote: { creator: Maybe<Pick<User, 'displayName'>> } },
>(): Column<T> => ({
  header: 'Requester',
  getValue: ({ requestForQuote }) => requestForQuote.creator?.displayName,
})

const getAssignedUserColumn = <
  T extends { requestForQuote: { assignedUser: Maybe<Pick<User, 'displayName'>> } },
>(): Column<T> => ({
  header: 'Purchaser',
  getValue: ({ requestForQuote }) => requestForQuote.assignedUser?.displayName,
})

const getVendorColumn = <
  T extends {
    requestForQuote: {
      reportingStoreOrders: (Pick<StoreOrder, 'state'> & { storeName: string })[]
    }
  },
>(
  useProcessedStoreOrders?: boolean
): Column<T> => ({
  header: 'Vendor',
  getValue: getColumnRequestVendors(useProcessedStoreOrders),
})

export default {
  getColumnRequestLink,
  getColumnRequestVendors,
  getRequestColumn,
  getCreatedColumn,
  getCreatorColumn,
  getVendorColumn,
  getAssignedUserColumn,
}
