import classNames from 'classnames'
import { DateTime } from 'luxon'
import { ReactNode } from 'react'
import PhoneM from '@/gf/modules/Phone'

import {
  AccountMachine,
  Address as AddressT,
  Organization,
  PartRequest,
  Vendor,
  VendorContact,
} from '../../types'

import logo from '@/retail/components/Layout/logo.png'

const Wrapper = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div
    className={classNames(
      'px-4 py-6 w-full max-w-2xl flex flex-col grow self-center gap-y-4 border border-gray-400 rounded-sm shadow-lg overflow-x-scroll',
      className
    )}
  >
    {children}
  </div>
)

const OrganizationLogo = ({ url }: { url: string }) => (
  <img className="block max-w-1/4 h-auto max-h-12 object-contain" src={url} alt="Logo" />
)

const VendorOrderEmail = ({
  readonly,
  showEmailCopy,
  customerName,
  customerPhoneNumber,
  customerEmail,
  organization,
  vendor,
  vendorContacts,
  machineDown,
  requestTimestamp,
  neededBy,
  shippingAddress,
  billingAddress,
  deliveryMethod,
  accountNumber,
  machine,
  partRequests,
  additionalDetails,
  imageUrls,
  organizationLogoUrl,
  hideUnitNumber = false,
}: {
  readonly: boolean
  showEmailCopy: boolean
  customerName: string | null
  customerPhoneNumber?: string
  customerEmail: string | null
  organization: Pick<Organization, 'name' | 'billingAddress'> | null
  vendor: Pick<Vendor, 'name'> | null
  vendorContacts?: Omit<VendorContact, 'preferred' | 'phoneNumber'>[]
  machineDown: boolean | null
  requestTimestamp: DateTime
  neededBy: DateTime | null
  shippingAddress: AddressT | null
  billingAddress: AddressT | null
  deliveryMethod?: 'SHIPPING' | 'PICKUP' | 'VENDOR_DELIVERY'
  accountNumber: string
  machine: Pick<AccountMachine, 'name' | 'serialNumber' | 'make' | 'model' | 'year'> | null
  partRequests: Pick<PartRequest, 'mpn' | 'description' | 'quantity'>[]
  additionalDetails: string
  imageUrls: string[]
  organizationLogoUrl: string | undefined | null
  hideUnitNumber?: boolean
}) => {
  return (
    <>
      {readonly && organizationLogoUrl && (
        <div className="flex justify-end">
          <OrganizationLogo url={organizationLogoUrl} />
        </div>
      )}
      <div className="text-center">
        <h1 className="text-2xl font-medium text-gray-700">You have a new purchase order</h1>
        <div className="pt-2 pb-4 leading-6">
          {customerName} at {organization?.name} sent you a purchase order on Gearflow. Select View
          Order to upload your invoice when the parts are ready.
        </div>
      </div>

      <hr className="my-4 border-gray-400" />

      <div className="grid grid-cols-2 gap-4">
        {/* Vendor */}
        <div className="text-sm text-gray-500">
          <p className="font-medium text-gray-700">Vendor</p>
          <p>{vendor?.name}</p>
          {vendorContacts && vendorContacts.length > 0 && (
            <p>
              <span className="text-gray-700">Contact name: </span> {vendorContacts[0].name}
            </p>
          )}
        </div>
        {/* Request Status */}
        <div className="text-sm text-gray-500">
          <p className="font-medium text-gray-700">Request Status</p>
          <p>
            <span className="text-gray-700">Requested date:</span>{' '}
            {requestTimestamp.setZone('GMT').toFormat("M/d/yy h:mma 'GMT'")}
          </p>
          <p>
            <span className="text-gray-700">Needed by:</span>{' '}
            {neededBy ? neededBy.setZone('GMT').toFormat("M/d/yy h:mma 'GMT'") : 'Unspecified'}
          </p>
          <p>
            <span className="text-gray-700">Machine down:</span> {machineDown ? 'Yes' : 'No'}
          </p>
        </div>
      </div>

      {/* Request From */}
      <div className="text-sm text-gray-500">
        <p className="font-medium text-gray-700">Purchase Order from</p>
        <p>{organization?.name}</p>
        <p>
          <span className="text-gray-700">Account number:</span> {accountNumber}
        </p>
        <p>
          <span className="text-gray-700">Requested by:</span> {customerName}
        </p>
        <p>
          <span className="text-gray-700">Phone:</span> {PhoneM.format(customerPhoneNumber || '')}
        </p>
        <p>
          <span className="text-gray-700">Email:</span> {customerEmail}
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        {/* Bill to */}
        <div className="text-sm text-gray-500">
          <p className="font-medium text-gray-700">Bill To</p>
          {billingAddress && (
            <>
              {billingAddress.companyName && <p>{billingAddress.companyName}</p>}
              {billingAddress.lineOne && <p>{billingAddress.lineOne}</p>}
              {billingAddress.lineTwo && <p>{billingAddress.lineTwo}</p>}
              <p>
                {billingAddress.city}, {billingAddress.state} {billingAddress.postalCode}
              </p>
            </>
          )}
        </div>
        {/* Ship to */}
        <div className="text-sm text-gray-500">
          <p className="font-medium text-gray-700">
            {deliveryMethod === 'VENDOR_DELIVERY'
              ? 'Delivery To'
              : deliveryMethod === 'PICKUP'
                ? 'Will Call'
                : 'Ship To'}
          </p>
          {deliveryMethod === 'PICKUP'
            ? 'In Store Pickup'
            : shippingAddress && (
                <>
                  {shippingAddress.companyName && <p>{shippingAddress.companyName}</p>}
                  {shippingAddress.lineOne && <p>{shippingAddress.lineOne}</p>}
                  {shippingAddress.lineTwo && <p>{shippingAddress.lineTwo}</p>}
                  <p>
                    {shippingAddress.city}, {shippingAddress.state} {shippingAddress.postalCode}
                  </p>
                </>
              )}
        </div>
      </div>

      {/* Fitment for Machine */}
      <div className="text-sm text-gray-500">
        <p className="font-medium text-gray-700">Fitment for Machine</p>
        {machine ? (
          <table className="w-full table-auto border-spacing-x-2">
            <thead className="text-gray-700 whitespace-nowrap text-left">
              <tr>
                {!hideUnitNumber && <th className="pr-1.5 py-0.5">Unit Number</th>}
                <th className="px-1.5 py-0.5">Serial Number</th>
                <th className="px-1.5 py-0.5">Make</th>
                <th className="px-1.5 py-0.5">Model</th>
                <th className="pl-1.5 py-0.5 text-right">Year</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {!hideUnitNumber && <td className="pr-1.5 py-0.5">{machine.name}</td>}
                <td className="px-1.5 py-0.5">{machine.serialNumber}</td>
                <td className="px-1.5 py-0.5">{machine.make}</td>
                <td className="px-1.5 py-0.5">{machine.model}</td>
                <td className="pl-1.5 py-0.5 text-right">{machine.year}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          'N/A (stock order request)'
        )}
      </div>

      {/* Parts Requested */}
      <div className="text-sm text-gray-500">
        <p className="font-medium text-gray-700">Parts Requested</p>
        <table className="w-full table-auto">
          <thead className="text-gray-700 whitespace-nowrap text-left">
            <tr>
              <th className="pr-1.5 py-0.5">Part #</th>
              <th className="px-1.5 py-0.5">Description</th>
              <th className="pl-1.5 py-0.5 text-right">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {partRequests.map((partRequest) => (
              <tr key={`${partRequest.mpn}-${partRequest.quantity}`}>
                <td className="pr-1.5 py-0.5">{partRequest.mpn}</td>
                <td className="px-1.5 py-0.5">{partRequest.description}</td>
                <td className="pl-1.5 py-0.5 text-right">{partRequest.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Additional details */}
      <div className="text-sm text-gray-500">
        <p className="font-medium text-gray-700">Additional Details</p>
        <p>{additionalDetails || 'N/A'}</p>
      </div>

      {/* Images */}
      <div className="flex flex-col items-start text-sm text-gray-500">
        <p className="font-medium text-gray-700">Images</p>
        {imageUrls.length > 0 ? (
          imageUrls.map((imageUrl) => (
            <a
              key={imageUrl}
              href={imageUrl}
              target="_blank"
              rel="noreferrer"
              className="flex max-w-xs w-full"
            >
              <img src={imageUrl} alt={imageUrl} />
            </a>
          ))
        ) : (
          <p>N/A</p>
        )}
      </div>

      {/* What is Gearflow Info */}
      <div className="mt-4 text-sm text-gray-500">
        <div className="flex flex-row justify-center items-center font-medium text-gray-700 border-t py-4">
          <span>Powered by</span>
          <img className="ml-1.5 inline w-auto h-3" src={logo} alt="Gearflow" />
        </div>
        <div className="px-3 py-2 text-sm text-gray-500 rounded">
          <p className="font-medium text-gray-700">Why is this request coming from Gearflow?</p>
          <p>{customerName} is using Gearflow to organize their parts orders and track spending.</p>
        </div>
        <div className="px-3 py-2 text-sm text-gray-500 rounded">
          <p className="font-medium text-gray-700">What is Gearflow?</p>
          <p>
            Gearflow is software built to make it easier for contractors to work with their vendors
            on parts.
          </p>
        </div>
        <div className="px-3 py-2 text-sm text-gray-500 rounded">
          <p className="font-medium text-gray-700">What happens next?</p>
          <p>Indicate when the order is ready for pickup or when it has shipped.</p>
          <p>You can also upload and send your invoice.</p>
        </div>
      </div>

      {/* Questions Text */}
      {showEmailCopy && (
        <div className="text-center text-xs text-gray-500">
          <span className="text-gray-700">Questions?</span>
          <br />
          Contact gearflow.com at orders@gearflow.com
          <br />
          or call at +1-800-554-3802
        </div>
      )}
    </>
  )
}

VendorOrderEmail.Wrapper = Wrapper
export default VendorOrderEmail
