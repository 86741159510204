import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import ExternalOrderForm from '../components/ExternalOrderForm'

const breadcrumbs = {
  copy: 'Back to Dashboard',
  crumbs: [
    { name: 'Orders', href: '/orders' },
    { name: 'Log Order', href: '/orders/create-external' },
  ],
}

const CreateExternalOrder = () => {
  return (
    <Frame breadcrumbs={breadcrumbs}>
      <Page title="Log Order">
        <ExternalOrderForm />
      </Page>
    </Frame>
  )
}

export default CreateExternalOrder
