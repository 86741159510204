import classNames from 'classnames'
import nth from 'lodash/nth'
import Dropdown from '@/gf/components/Dropdown'
import { RequestsStoreOrdersQuery } from '@/buyers/_gen/gql'

type Store = RequestsStoreOrdersQuery['requestForQuotes'][number]['storeOrders'][number]['store']

const StoreLine = ({ name }) => <p className="truncate max-w-72">{name}</p>

const Store = ({ name, otherStoresCount, className = '' }) => (
  <div className={classNames('flex gap-2 text-left', className)}>
    <StoreLine name={name} />

    {otherStoresCount > 0 && (
      <div className="flex-shrink-0">
        <span className="font-medium text-xs bg-slate-100 text-slate-500 rounded-full px-1 py-0.5 leading-1">
          + {otherStoresCount}
        </span>
      </div>
    )}
  </div>
)

const Stores = ({ stores }: { stores: Store[] }) => {
  const firstStore = nth(stores, 0)
  const [_, ...otherStores] = stores

  return otherStores.length === 0 ? (
    <div className="flex gap-x-1">
      {firstStore && (
        <Store className="px-6 py-4" name={firstStore.name} otherStoresCount={otherStores.length} />
      )}
    </div>
  ) : (
    <Dropdown
      title={<Store name={firstStore?.name} otherStoresCount={otherStores.length} />}
      stopEventPropagation
    >
      <div className="flex flex-col gap-y-2">
        {otherStores.map((store) => (
          <StoreLine key={store.id} name={store.name} />
        ))}
      </div>
    </Dropdown>
  )
}

export default Stores
