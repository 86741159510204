import Page from '@/gf/components/Page'
import Frame from '../components/Frame'
import useToggle from '@/gf/hooks/useToggle'
import UploadPartsManual from '../components/UploadPartsManual'
import { BookOpenIcon } from '@heroicons/react/outline'
import Action from '@/gf/components/Action'
import Pagination from '@/gf/components/Pagination'

import useGqlClient from '../hooks/useGqlClient'
import useSession from '../hooks/useSession'
import usePage from '@/gf/hooks/usePage'
import Card from '@/gf/components/Card'
import { Td, Th, Tr } from '@/gf/components/Table'
import Link from '@/gf/components/Link'
import TextTag from '@/gf/components/next/TextTag'
import { ManualState, useListManualsQuery } from '../_gen/gql'
import useModal from '@/gf/hooks/useModal'
import AssignOrgMachinesModal from './KnowledgeCenter/AssignOrgMachinesModal'
import Id from '@/gf/modules/Id'
import pluralize from 'pluralize'

const KnowledgeCenter = () => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const [page, setPage] = usePage()
  const { data } = useListManualsQuery({ client, variables: { orgId, page } })
  const [uploadPartsManualOpen, uploadPartsManualToggle] = useToggle()
  const assignMachinesModal = useModal<{ partsManualId: string }>()

  if (!data) {
    return null
  }

  const partsManuals = data.manuals.entries

  return (
    <Frame
      breadcrumbs={{
        copy: 'Back to Dashboard',
        crumbs: [{ name: 'Knowledge Center', href: '/knowledge-center' }],
      }}
    >
      <UploadPartsManual open={uploadPartsManualOpen} onClose={uploadPartsManualToggle.off} />
      {assignMachinesModal.props?.partsManualId && (
        <AssignOrgMachinesModal
          open={assignMachinesModal.isOpen}
          onClose={assignMachinesModal.close}
          partsManualId={assignMachinesModal.props?.partsManualId}
        />
      )}

      <Page
        title="Knowledge Center"
        actionsNext={[
          <Action.P
            onClick={uploadPartsManualToggle.on}
            className="items-center flex gap-x-2"
            color="blue"
            key="upload-parts-manual-button"
          >
            <BookOpenIcon className="inline-block h-4 w-4" />
            Upload Parts Manual
          </Action.P>,
        ]}
      >
        <Card className="overflow-auto mt-6">
          <table className="min-w-full divide-y-1 divide-gray-200">
            <thead className="bg-gray-50 sticky top-0">
              <Tr>
                <Th>State</Th>
                <Th>Id</Th>
                <Th>Brand</Th>
                <Th>Models</Th>
                <Th></Th>
                <Th>Serial Numbers</Th>
                <Th>Equipment Type</Th>
                <Th>Publication Date</Th>
              </Tr>
            </thead>
            <tbody className="divide-y-1 divide-gray-200">
              {partsManuals.length === 0 ? (
                <Tr>
                  <Td colSpan={8}>No manuals found.</Td>
                </Tr>
              ) : (
                partsManuals.map((manual) => (
                  <Tr key={manual.id}>
                    <Td className="capitalize">
                      <TextTag
                        color={
                          manual.state === ManualState.Queued
                            ? 'gray'
                            : manual.state === ManualState.Reading
                              ? 'blue'
                              : manual.state === ManualState.Extracting
                                ? 'orange'
                                : manual.state === ManualState.Ready
                                  ? 'green'
                                  : 'red'
                        }
                      >
                        {manual.state.toLocaleLowerCase()}
                      </TextTag>
                    </Td>
                    <Td>
                      <Link.T to={`/parts-manual/${manual.id}`}>{Id.shorten(manual.id)}</Link.T>
                    </Td>
                    <Td>{manual.brand}</Td>
                    <Td>{manual.models.join(', ')}</Td>
                    <Td>
                      {manual.orgMachines.length > 0 && (
                        <span className="text-sm italic text-gray-500 block">
                          {manual.orgMachines.length}{' '}
                          {pluralize('machine', manual.orgMachines.length)}
                        </span>
                      )}

                      <Action.T
                        onClick={() => assignMachinesModal.open({ partsManualId: manual.id })}
                        className="block"
                      >
                        Link Machines
                      </Action.T>
                    </Td>
                    <Td>{manual.serialNumbers.join(', ')}</Td>
                    <Td>{manual.equipmentType}</Td>
                    <Td>{manual.publicationDate}</Td>
                  </Tr>
                ))
              )}
            </tbody>
          </table>
        </Card>

        <div className="mt-4">
          <Pagination pagination={data.manuals?.pagination} page={page} updatePage={setPage} />
        </div>
      </Page>
    </Frame>
  )
}

export default KnowledgeCenter
