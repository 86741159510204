import { DocumentTextIcon, PencilIcon } from '@heroicons/react/outline'

import { InvoiceV2, useAddFileToInvoiceMutation } from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import Link from '@/gf/components/Link'
import Spinner from '@/gf/components/Spinner'
import UploadButton from '@/gf/components/UploadButton'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import classnames from 'classnames'
import DownloadInvoiceButton from './DownloadInvoiceButton'

interface Props {
  invoice: InvoiceV2
  onUploadComplete?: () => void
}

const InvoiceUpload = ({ invoice, onUploadComplete }: Props) => {
  const [_, msgs] = useMsgs()
  const [uploading, uploadToggle] = useToggle()
  const client = useGqlClient()

  const [addFileToInvoice] = useAddFileToInvoiceMutation({
    client,
  })

  return (
    <>
      {invoice.processingStatus === 'succeeded' ? (
        <DownloadInvoiceButton invoice={invoice} />
      ) : (
        <div
          className={classnames('rounded-lg border border-gray-200 p-2.5', {
            'border-dashed border-2': invoice.processingStatus !== 'failed' || uploading,
          })}
        >
          <div className="flex flex-col items-center gap-4">
            <div className="flex items-center gap-3">
              {uploading ? (
                <>
                  <Spinner size="xs" className="mx-0" />
                  <span className="text-sm font-medium text-gray-700">Uploading Invoice</span>
                </>
              ) : invoice.processingStatus === 'failed' ? (
                <span className="font-medium text-gray-700">Invoice Processing Failed</span>
              ) : invoice.processingStatus === 'in_progress' ? (
                <>
                  <Spinner size="xs" className="mx-0" />
                  <span className="text-sm font-medium text-gray-700">Processing Invoice</span>
                </>
              ) : null}
            </div>
            {invoice.processingStatus === 'in_progress' && !uploading && (
              <span className="text-sm text-gray-500">
                Your invoice price and availability will display on your order once complete.
              </span>
            )}
            {invoice.processingStatus === 'failed' && !uploading && (
              <>
                <span className="text-sm text-gray-500">
                  We weren't able to display your invoice price and availability. Try by uploading
                  your invoice again, or enter manually.
                </span>

                <UploadButton
                  allowedFileTypes={['.pdf', 'application/pdf']}
                  onUploadComplete={async (uploadedFiles) => {
                    try {
                      await addFileToInvoice({
                        variables: {
                          url: uploadedFiles[0].url,
                          invoiceId: invoice.id,
                        },
                      })

                      msgs.add('Invoice uploaded successfully!', 'positive')
                      uploadToggle.off()
                      onUploadComplete?.()
                    } catch (err) {
                      msgs.add('Failed to upload invoice. Please try again.', 'negative')
                    }
                  }}
                  onUploadStarted={uploadToggle.on}
                  onError={(err) => {
                    msgs.add(err || 'Failed to upload file. Please try again.', 'negative')
                    uploadToggle.off()
                  }}
                  className="w-full"
                >
                  <div className="flex flex-col gap-2 border-2 border-dashed border-gray-200 rounded-lg p-2 w-full items-center">
                    <div className="flex items-center bg-white rounded-lg shadow-sm border border-gray-200 py-2 px-3 gap-1.5 hover:bg-gray-50 w-full justify-center">
                      <DocumentTextIcon className="h-5 w-5 text-gray-600" />
                      <span className="text-sm font-medium text-gray-700">Upload Invoice</span>
                    </div>
                    <span className="text-gray-500 text-sm font-medium">or drag & drop here</span>
                  </div>
                </UploadButton>

                <Link.S to="./update" className="w-full">
                  <PencilIcon className="inline-block w-5 h-5" /> Update Price and Availability
                </Link.S>
              </>
            )}
          </div>

          {invoice.processingStatus === null && !uploading && (
            <UploadButton
              allowedFileTypes={['.pdf', 'application/pdf']}
              onUploadComplete={async (uploadedFiles) => {
                try {
                  await addFileToInvoice({
                    variables: {
                      url: uploadedFiles[0].url,
                      invoiceId: invoice.id,
                    },
                  })

                  msgs.add('Invoice uploaded successfully!', 'positive')
                  uploadToggle.off()
                  onUploadComplete?.()
                } catch (err) {
                  msgs.add('Failed to upload invoice. Please try again.', 'negative')
                }
              }}
              onUploadStarted={uploadToggle.on}
              onError={(err) => {
                msgs.add(err || 'Failed to upload file. Please try again.', 'negative')
                uploadToggle.off()
              }}
              className="w-full"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center bg-white rounded-lg shadow-sm border border-gray-200 p-2 gap-1.5 hover:bg-gray-50">
                  <DocumentTextIcon className="h-5 w-5 text-gray-600" />
                  <span className="text-sm font-medium text-gray-700">Upload Invoice</span>
                </div>
                <span className="text-gray-500 text-sm font-medium">or drag & drop here</span>
              </div>
            </UploadButton>
          )}
        </div>
      )}
    </>
  )
}

export default InvoiceUpload
