import {
  RequestsByIdsQuery,
  StoreRequestMetricsDocument,
  StoreRequestMetricsQuery,
  StoreRequestMetricsQueryVariables,
  useRequestsByIdsLazyQuery,
} from '@/buyers/_gen/gql'
import { QueryHookOptions, useQuery } from '@apollo/client'
import { useState } from 'react'
import chunk from 'lodash/chunk'
import uniq from 'lodash/uniq'

type StoreRequestMetric = StoreRequestMetricsQuery['storeRequestMetrics'][number]

type RequestForQuote = RequestsByIdsQuery['requestForQuotes'][number]

export interface StoreRequestMetricWithRfq extends StoreRequestMetric {
  requestForQuote: RequestForQuote
}

export type StoreRequestMetricsAggregated = {
  storeRequestMetrics: Array<StoreRequestMetricWithRfq>
}

function useStoreRequestMetricsPaginated(
  options: QueryHookOptions<StoreRequestMetricsQuery, StoreRequestMetricsQueryVariables> &
    ({ variables: StoreRequestMetricsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const [fetchRequestsByIds] = useRequestsByIdsLazyQuery({ client: options.client })
  const [prevData, setPrevData] = useState<StoreRequestMetricsAggregated>()
  const [data, setData] = useState<StoreRequestMetricsAggregated>()
  const [loadingRequests, setLoadingRequests] = useState<boolean>(false)

  const fetchAllRequests = async (metricsData: StoreRequestMetricsQuery) => {
    const requestIds = metricsData.storeRequestMetrics.map((m) => m.requestForQuoteId)
    const chunks = chunk(uniq(requestIds), 250)

    const requests: Record<string, RequestForQuote> = {}
    for (let i = 0; i < chunks.length; i++) {
      const { data: requestsData } = await fetchRequestsByIds({ variables: { ids: chunks[i] } })
      const pageResults = requestsData?.requestForQuotes ?? []

      for (let j = 0; j < pageResults.length; j++) {
        const rfq = pageResults[j]
        requests[rfq.id] = pageResults[j]
      }
    }

    const storeRequestMetrics = metricsData.storeRequestMetrics.map((m) => ({
      ...m,
      requestForQuote: requests[m.requestForQuoteId],
    }))

    setData({ ...metricsData, storeRequestMetrics })
    setLoadingRequests(false)
  }

  const {
    refetch,
    loading: loadingBaseMetrics,
    error,
  } = useQuery<StoreRequestMetricsQuery, StoreRequestMetricsQueryVariables>(
    StoreRequestMetricsDocument,
    {
      ...options,
      onCompleted(metricsData) {
        setPrevData(data)
        setData(undefined)
        setLoadingRequests(true)

        fetchAllRequests(metricsData)
      },
    }
  )

  return { data, prevData, loading: loadingBaseMetrics || loadingRequests, refetch, error }
}

export default useStoreRequestMetricsPaginated
