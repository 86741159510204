import { ValueIteratee, clamp, groupBy, mapValues, mean } from 'lodash'
import { DateTime, Interval } from 'luxon'
import { count, max, median, min, std, sum } from 'mathjs'
import { Money } from '@/types'
import { DATE_FORMAT } from '@/gf/components/Reports/DurationInput'
import { StoreRequestMetricWithRfq } from '../pages/Reporting/useStoreRequestMetricsPaginated'

export const MIN_REQUESTS_FOR_REPORTING = 20

// Use this with array.filter(Reporting.isNotNullable)
function isNotNullable<T>(value: T | null | undefined): value is T {
  return value !== null && typeof value !== 'undefined'
}

const aggregateMetricValues = <T, U extends object | number>(
  metrics: T[],
  getMetricValue: (metric: T) => number | null,
  getAggregate: (values: number[]) => U
) => {
  const values = metrics.map(getMetricValue).filter(isNotNullable)
  return values.length > 0 ? getAggregate(values) : null
}

const groupAndAggregate = <T, U>(
  metrics: T[],
  groupByIteratee: ValueIteratee<T>,
  aggregateGroup: (groups: T[]) => U
) => mapValues(groupBy(metrics, groupByIteratee), aggregateGroup)

// Aggregate functions -----------------------------------------------------------------------------------------------------

const getStandardAggregate = (values: number[]) => ({
  median: median(values),
  mean: mean(values),
  sum: sum(values),
  count: count(values),
  std: std<number>(...values),
  min: min(values),
  max: max(values),
})

// 0 = false
// 1 = true
const getStandardBooleanAggregate = (values: number[]) => {
  const sumValues = sum(values)
  const countValues = count(values)
  const inverseSumValues = sum(values.map((value) => (value === 0 ? 1 : 0)))
  return {
    sum: sumValues,
    count: countValues,
    percent: sumValues / countValues,
    inverseSum: inverseSumValues,
    inversePercent: inverseSumValues / countValues,
  }
}

export type StandardAggregateT = ReturnType<typeof getStandardAggregate>
export type StandardBooleanAggregateT = ReturnType<typeof getStandardBooleanAggregate>

const getSecondDegreeStandardAggregate = <T>(
  metrics: T[],
  getMetricValue: (metric: T) => StandardAggregateT | null
) => ({
  median: aggregateMetricValues(
    metrics,
    (metric) => getMetricValue(metric)?.median ?? null,
    getStandardAggregate
  ),
  mean: aggregateMetricValues(
    metrics,
    (metric) => getMetricValue(metric)?.mean ?? null,
    getStandardAggregate
  ),
  sum: aggregateMetricValues(
    metrics,
    (metric) => getMetricValue(metric)?.sum ?? null,
    getStandardAggregate
  ),
  count: aggregateMetricValues(
    metrics,
    (metric) => getMetricValue(metric)?.count ?? null,
    getStandardAggregate
  ),
  std: aggregateMetricValues(
    metrics,
    (metric) => getMetricValue(metric)?.std ?? null,
    getStandardAggregate
  ),
})

const getSecondDegreeStandardBooleanAggregate = <T>(
  metrics: T[],
  getMetricValue: (metric: T) => StandardBooleanAggregateT | null
) => ({
  sum: aggregateMetricValues(
    metrics,
    (metric) => getMetricValue(metric)?.sum ?? null,
    getStandardAggregate
  ),
  count: aggregateMetricValues(
    metrics,
    (metric) => getMetricValue(metric)?.count ?? null,
    getStandardAggregate
  ),
  percent: aggregateMetricValues(
    metrics,
    (metric) => getMetricValue(metric)?.percent ?? null,
    getStandardAggregate
  ),
})

export type SecondDegreeStandardAggregateT = ReturnType<typeof getSecondDegreeStandardAggregate>
export type SecondDegreeStandardBooleanAggregateT = ReturnType<
  typeof getSecondDegreeStandardBooleanAggregate
>

// Aggregate lifespanDuration
const aggregateStandardLifespanDuration = (groupMetrics: { lifespanDuration: number | null }[]) =>
  aggregateMetricValues(
    groupMetrics,
    ({ lifespanDuration }) => lifespanDuration ?? null,
    getStandardAggregate
  )

// Aggregate requestApprovalDuration
const aggregateStandardRequestApprovalDuration = (
  groupMetrics: { requestApprovalDuration: number | null }[]
) =>
  aggregateMetricValues(
    groupMetrics,
    ({ requestApprovalDuration }) => requestApprovalDuration ?? null,
    getStandardAggregate
  )

// Aggregate responseDuration
const aggregateStandardResponseDuration = (groupMetrics: { responseDuration: number | null }[]) =>
  aggregateMetricValues(
    groupMetrics,
    ({ responseDuration }) => responseDuration ?? null,
    getStandardAggregate
  )

// Aggregate quoteApprovalDuration
const aggregateStandardQuoteApprovalDuration = (
  groupMetrics: { quoteApprovalDuration: number | null }[]
) =>
  aggregateMetricValues(
    groupMetrics,
    ({ quoteApprovalDuration }) => quoteApprovalDuration ?? null,
    getStandardAggregate
  )

// Aggregate orderFulfillmentDuration
const aggregateStandardOrderFulfillmentDuration = (
  groupMetrics: { orderFulfillmentDuration: number | null }[]
) =>
  aggregateMetricValues(
    groupMetrics,
    ({ orderFulfillmentDuration }) => orderFulfillmentDuration ?? null,
    getStandardAggregate
  )

// Aggregate total
const aggregateStandardTotal = (groupMetrics: { total: Money | number | null }[]) =>
  aggregateMetricValues(
    groupMetrics,
    ({ total }) => (typeof total === 'object' ? total?.amount : total) ?? null,
    getStandardAggregate
  )

// Aggregate urgent
const aggregateStandardUrgent = (groupMetrics: { requestForQuote: { isUrgent: boolean } }[]) =>
  aggregateMetricValues(
    groupMetrics,
    ({ requestForQuote: { isUrgent } }) => (isUrgent ? 1 : 0),
    getStandardBooleanAggregate
  )

// Aggregate accuracy
const aggregateStandardAccurate = (groupMetrics: { requestForQuote: { isAccurate: boolean } }[]) =>
  aggregateMetricValues(
    groupMetrics,
    ({ requestForQuote: { isAccurate } }) => (isAccurate ? 1 : 0),
    getStandardBooleanAggregate
  )

// Aggregate internalFulfillment
const aggregateStandardInternalFulfillment = (
  groupMetrics: { requestForQuote: { isInternalFulfillment: boolean } }[]
) =>
  aggregateMetricValues(
    groupMetrics,
    ({ requestForQuote: { isInternalFulfillment } }) => (isInternalFulfillment ? 1 : 0),
    getStandardBooleanAggregate
  )

// Aggregate cost savings
const aggregateStandardCostSavings = (groupMetrics: { costSavings: Money | number | null }[]) =>
  aggregateMetricValues(
    groupMetrics,
    ({ costSavings }) =>
      (typeof costSavings === 'object' ? costSavings?.amount : costSavings) ?? null,
    getStandardAggregate
  )

// Aggregate time savings
const aggregateStandardTimeSavings = (groupMetrics: { timeSavings: number | null }[]) =>
  aggregateMetricValues(
    groupMetrics,
    ({ timeSavings }) => timeSavings ?? null,
    getStandardAggregate
  )

// Group and Aggregate functions ---------------------------------------------------------------------------------------

// Group and Aggregate StoreRequestMetrics by Request
const getRequestMetrics = (storeRequestMetrics: StoreRequestMetricWithRfq[]) =>
  groupAndAggregate(
    storeRequestMetrics,
    ({ requestForQuote }) => requestForQuote.id,
    (groupStoreRequestMetrics) => ({
      requestForQuote: groupStoreRequestMetrics[0].requestForQuote,
      lifespanDuration: aggregateMetricValues(
        groupStoreRequestMetrics,
        ({ lifespanDuration }) => lifespanDuration,
        (lifespanDurations) => max(lifespanDurations)
      ),
      requestApprovalDuration: aggregateMetricValues(
        groupStoreRequestMetrics,
        ({ requestApprovalDuration }) => requestApprovalDuration,
        (requestApprovalDurations) => max(requestApprovalDurations)
      ),
      responseDuration: aggregateMetricValues(
        groupStoreRequestMetrics,
        ({ responseDuration }) => responseDuration,
        (responseDurations) => max(responseDurations)
      ),
      quoteApprovalDuration: aggregateMetricValues(
        groupStoreRequestMetrics,
        ({ quoteApprovalDuration }) => quoteApprovalDuration,
        (quoteApprovalDurations) => max(quoteApprovalDurations)
      ),
      orderFulfillmentDuration: aggregateMetricValues(
        groupStoreRequestMetrics,
        ({ orderFulfillmentDuration }) => orderFulfillmentDuration,
        (orderFulfillmentDurations) => max(orderFulfillmentDurations)
      ),
      total: aggregateMetricValues(
        groupStoreRequestMetrics,
        ({ total }) => total?.amount ?? null,
        (totals) => sum(totals)
      ),
      isUrgent: aggregateMetricValues(
        groupStoreRequestMetrics,
        // Using 1 and 0 instead of boolean values to make counting easier
        ({ requestForQuote: { isUrgent } }) => (isUrgent ? 1 : 0),
        (urgentValues) => (sum(urgentValues) > 0 ? 1 : 0)
      ),
      isAccurate: aggregateMetricValues(
        groupStoreRequestMetrics,
        // Using 1 and 0 instead of boolean values to make counting easier
        ({ requestForQuote: { isAccurate } }) => (isAccurate ? 1 : 0),
        (accurateValues) => (sum(accurateValues) === count(accurateValues) ? 1 : 0)
      ),
      isInternalFulfillment: aggregateMetricValues(
        groupStoreRequestMetrics,
        // Using 1 and 0 instead of boolean values to make counting easier
        ({ requestForQuote: { isInternalFulfillment } }) => (isInternalFulfillment ? 1 : 0),
        (isInternalFulfillmentValues) =>
          sum(isInternalFulfillmentValues) === count(isInternalFulfillmentValues) ? 1 : 0
      ),
      costSavings: aggregateMetricValues(
        groupStoreRequestMetrics,
        ({ costSavings }) => costSavings?.amount ?? null,
        (costSavings) => sum(costSavings)
      ),
      timeSavings: aggregateMetricValues(
        groupStoreRequestMetrics,
        ({ timeSavings }) => timeSavings,
        (timeSavings) => sum(timeSavings)
      ),
    })
  )

type RequestMetricT = ReturnType<typeof getRequestMetrics>[string]

// Common aggregate function for the reporting metrics
const aggregateCommonMetrics = (
  groupMetrics: Pick<
    StoreRequestMetricWithRfq | RequestMetricT,
    | 'lifespanDuration'
    | 'requestApprovalDuration'
    | 'responseDuration'
    | 'quoteApprovalDuration'
    | 'orderFulfillmentDuration'
    | 'total'
    | 'requestForQuote'
    | 'costSavings'
    | 'timeSavings'
  >[]
) => ({
  lifespanDuration: aggregateStandardLifespanDuration(groupMetrics),
  requestApprovalDuration: aggregateStandardRequestApprovalDuration(groupMetrics),
  responseDuration: aggregateStandardResponseDuration(groupMetrics),
  quoteApprovalDuration: aggregateStandardQuoteApprovalDuration(groupMetrics),
  orderFulfillmentDuration: aggregateStandardOrderFulfillmentDuration(groupMetrics),
  total: aggregateStandardTotal(groupMetrics),
  urgent: aggregateStandardUrgent(groupMetrics),
  accurate: aggregateStandardAccurate(groupMetrics),
  internalFulfillment: aggregateStandardInternalFulfillment(groupMetrics),
  costSavings: aggregateStandardCostSavings(groupMetrics),
  timeSavings: aggregateStandardTimeSavings(groupMetrics),
})

// Group and Aggregate StoreRequestMetrics by Store
const getStoreMetrics = (storeRequestMetrics: StoreRequestMetricWithRfq[]) =>
  groupAndAggregate(
    storeRequestMetrics,
    ({ store }) => store?.id,
    (groupMetrics) => ({
      store: groupMetrics[0].store,
      ...aggregateCommonMetrics(groupMetrics),
    })
  )

// Group and Aggregate RequestMetrics by User
const getUserMetrics = (requestMetrics: RequestMetricT[]) =>
  groupAndAggregate(
    requestMetrics,
    ({ requestForQuote }) => requestForQuote.user?.id,
    (groupMetrics) => ({
      user: groupMetrics[0].requestForQuote.user,
      ...aggregateCommonMetrics(groupMetrics),
    })
  )

// Group and Aggregate RequestMetrics by Creator
const getCreatorMetrics = (requestMetrics: RequestMetricT[]) =>
  groupAndAggregate(
    requestMetrics,
    ({ requestForQuote }) => requestForQuote.creator?.id,
    (groupMetrics) => ({
      creator: groupMetrics[0].requestForQuote.creator,
      ...aggregateCommonMetrics(groupMetrics),
    })
  )

// Group and Aggregate RequestMetrics by Assigned user (Purchaser)
const getPurchaserMetrics = (requestMetrics: RequestMetricT[]) =>
  groupAndAggregate(
    requestMetrics,
    ({ requestForQuote }) => requestForQuote.assignedUser?.id,
    (groupMetrics) => ({
      assignedUser: groupMetrics[0].requestForQuote.assignedUser,
      ...aggregateCommonMetrics(groupMetrics),
    })
  )

// Group and Aggregate RequestMetrics by Machine Subcategory
const getCategoryMetrics = (requestMetrics: RequestMetricT[]) =>
  groupAndAggregate(
    requestMetrics.flatMap((requestMetric) =>
      requestMetric.requestForQuote.orgMachines.length === 0
        ? [
            {
              ...requestMetric,
              category: {
                id: 'no_machine',
                name: 'No Machine',
              },
            },
          ]
        : requestMetric.requestForQuote.orgMachines.flatMap((orgMachine) =>
            orgMachine.machine.subcategories.length > 0
              ? orgMachine.machine.subcategories.map((s) => ({
                  ...requestMetric,
                  category: {
                    id: s.id,
                    name: s.name,
                  },
                }))
              : [{ ...requestMetric, category: { id: 'unknown', name: 'Unknown' } }]
          )
    ),
    ({ category }) => category.id,
    (groupMetrics) => ({
      category: groupMetrics[0].category,
      ...aggregateCommonMetrics(groupMetrics),
    })
  )

// Group and Aggregate RequestMetrics by Machine
const getMachineMetrics = (requestMetrics: RequestMetricT[]) =>
  groupAndAggregate(
    // TODO: do a better job of this by including org machine in the group by or using a seperate query?
    requestMetrics.flatMap((requestMetric) =>
      requestMetric.requestForQuote.orgMachines.map((orgMachine) => ({
        ...requestMetric,
        orgMachine,
      }))
    ),
    ({ orgMachine }) => orgMachine.id,
    (groupMetrics) => ({
      orgMachine: groupMetrics[0].orgMachine,
      ...aggregateCommonMetrics(groupMetrics),
    })
  )

// Group and Aggregate RequestMetrics by Urgency
const getUrgencyMetrics = (requestMetrics: RequestMetricT[]) =>
  groupAndAggregate(
    requestMetrics,
    ({ requestForQuote }) => requestForQuote.urgency,
    (groupMetrics) => ({
      urgency: groupMetrics[0].requestForQuote.urgency,
      ...aggregateCommonMetrics(groupMetrics),
    })
  )

// Group and Aggregate RequestMetrics by insertedAt
const getInsertedAtDateMetrics = (requestMetrics: RequestMetricT[]) =>
  groupAndAggregate(
    requestMetrics,
    ({ requestForQuote }) => requestForQuote.insertedAt.toFormat(DATE_FORMAT),
    (groupMetrics) => ({
      insertedAt: groupMetrics[0].requestForQuote.insertedAt,
      ...aggregateCommonMetrics(groupMetrics),
    })
  )

// Group StoreRequestMetrics by Store, then Group and Aggregate by insertedAt
const getStoreInsertedAtDateMetrics = (storeRequestMetrics: StoreRequestMetricWithRfq[]) =>
  groupAndAggregate(
    storeRequestMetrics,
    ({ store }) => store?.id,
    (groupStoreRequestMetrics) =>
      groupAndAggregate(
        groupStoreRequestMetrics,
        ({ requestForQuote }) => requestForQuote.insertedAt.toFormat(DATE_FORMAT),
        (groupMetrics) => ({
          insertedAt: groupMetrics[0].requestForQuote.insertedAt,
          ...aggregateCommonMetrics(groupMetrics),
        })
      )
  )

const getGroupedInsertedAtDateMetrics = <T extends StoreRequestMetricWithRfq | RequestMetricT>(
  metrics: T[],
  groupByIteratee: ValueIteratee<T>
) =>
  groupAndAggregate(metrics, groupByIteratee, (groupRequestMetrics) =>
    groupAndAggregate(
      groupRequestMetrics,
      ({ requestForQuote }) => requestForQuote.insertedAt.toFormat(DATE_FORMAT),
      (groupMetrics) => ({
        insertedAt: groupMetrics[0].requestForQuote.insertedAt,
        ...aggregateCommonMetrics(groupMetrics),
      })
    )
  )

type InsertedAtDateMetricT = ReturnType<typeof getInsertedAtDateMetrics>
const getChartData = (
  insertedAtDateMetrics: InsertedAtDateMetricT | null,
  durationStart: DateTime,
  durationEnd: DateTime,
  getValue: (insertedAtDateMetric: InsertedAtDateMetricT[string]) => number | undefined
) =>
  Interval.fromDateTimes(durationStart.startOf('day'), durationEnd.endOf('day'))
    .splitBy({ days: 1 })
    .map((chartDate: Interval<true>) => {
      const chartDateString = chartDate.start.toFormat(DATE_FORMAT)

      const value =
        insertedAtDateMetrics && insertedAtDateMetrics[chartDateString]
          ? getValue(insertedAtDateMetrics[chartDateString])
          : undefined

      return {
        x: chartDateString,
        y:
          typeof value !== 'undefined'
            ? value
            : chartDate.isBefore(DateTime.now().plus({ days: 1 }).startOf('day'))
              ? 0
              : null,
      }
    })

// Aggregate functions -------------------------------------------------------------------------------------------------

// TODO: create a generalized helper function for this
// Aggregate First degree Metrics. Where the values are values (not aggregates)
const aggregateFirstDegreeMetrics = <
  T extends Pick<
    RequestMetricT,
    | 'lifespanDuration'
    | 'requestApprovalDuration'
    | 'responseDuration'
    | 'quoteApprovalDuration'
    | 'orderFulfillmentDuration'
    | 'total'
    | 'isUrgent'
    | 'isAccurate'
    | 'isInternalFulfillment'
    | 'costSavings'
    | 'timeSavings'
  >,
>(
  requestMetrics: T[]
) => ({
  lifespanDuration: aggregateMetricValues(
    requestMetrics,
    ({ lifespanDuration }) => lifespanDuration,
    getStandardAggregate
  ),
  requestApprovalDuration: aggregateMetricValues(
    requestMetrics,
    ({ requestApprovalDuration }) => requestApprovalDuration,
    getStandardAggregate
  ),
  responseDuration: aggregateMetricValues(
    requestMetrics,
    ({ responseDuration }) => responseDuration,
    getStandardAggregate
  ),
  quoteApprovalDuration: aggregateMetricValues(
    requestMetrics,
    ({ quoteApprovalDuration }) => quoteApprovalDuration,
    getStandardAggregate
  ),
  orderFulfillmentDuration: aggregateMetricValues(
    requestMetrics,
    ({ orderFulfillmentDuration }) => orderFulfillmentDuration,
    getStandardAggregate
  ),
  total: aggregateMetricValues(requestMetrics, ({ total }) => total, getStandardAggregate),
  urgent: aggregateMetricValues(
    requestMetrics,
    ({ isUrgent }) => isUrgent,
    getStandardBooleanAggregate
  ),
  accurate: aggregateMetricValues(
    requestMetrics,
    ({ isAccurate }) => isAccurate,
    getStandardBooleanAggregate
  ),
  internalFulfillment: aggregateMetricValues(
    requestMetrics,
    ({ isInternalFulfillment }) => isInternalFulfillment,
    getStandardBooleanAggregate
  ),
  costSavings: aggregateMetricValues(
    requestMetrics,
    ({ costSavings }) => costSavings,
    getStandardAggregate
  ),
  timeSavings: aggregateMetricValues(
    requestMetrics,
    ({ timeSavings }) => timeSavings,
    getStandardAggregate
  ),
})

// TODO: create a generalized helper function for this
// Aggregate Second degree Metrics (where the values are aggregates)
const aggregateSecondDegreeMetrics = <
  GroupMetric extends {
    lifespanDuration: StandardAggregateT | null
    requestApprovalDuration: StandardAggregateT | null
    responseDuration: StandardAggregateT | null
    quoteApprovalDuration: StandardAggregateT | null
    orderFulfillmentDuration: StandardAggregateT | null
    total: StandardAggregateT | null
    urgent: StandardBooleanAggregateT | null
    accurate: StandardBooleanAggregateT | null
    internalFulfillment: StandardBooleanAggregateT | null
    costSavings: StandardAggregateT | null
    timeSavings: StandardAggregateT | null
  },
>(
  groupMetrics: GroupMetric[]
) => ({
  lifespanDuration: getSecondDegreeStandardAggregate(
    groupMetrics,
    ({ lifespanDuration }) => lifespanDuration
  ),
  requestApprovalDuration: getSecondDegreeStandardAggregate(
    groupMetrics,
    ({ requestApprovalDuration }) => requestApprovalDuration
  ),
  responseDuration: getSecondDegreeStandardAggregate(
    groupMetrics,
    ({ responseDuration }) => responseDuration
  ),
  quoteApprovalDuration: getSecondDegreeStandardAggregate(
    groupMetrics,
    ({ quoteApprovalDuration }) => quoteApprovalDuration
  ),
  orderFulfillmentDuration: getSecondDegreeStandardAggregate(
    groupMetrics,
    ({ orderFulfillmentDuration }) => orderFulfillmentDuration
  ),
  total: getSecondDegreeStandardAggregate(groupMetrics, ({ total }) => total),
  urgent: getSecondDegreeStandardBooleanAggregate(groupMetrics, ({ urgent }) => urgent),
  accurate: getSecondDegreeStandardBooleanAggregate(groupMetrics, ({ accurate }) => accurate),
  internalFulfillment: getSecondDegreeStandardBooleanAggregate(
    groupMetrics,
    ({ internalFulfillment }) => internalFulfillment
  ),
  costSavings: getSecondDegreeStandardAggregate(groupMetrics, ({ costSavings }) => costSavings),
  timeSavings: getSecondDegreeStandardAggregate(groupMetrics, ({ timeSavings }) => timeSavings),
})

// Impact functions ----------------------------------------------------------------------------------------------------

const linearInterpolate = (
  input: number,
  inputRange: [number, number],
  outputRange: [number, number]
) => {
  const inputStart = inputRange[0]
  const inputEnd = inputRange[1]
  const inputLength = inputEnd - inputStart
  const outputStart = outputRange[0]
  const outputEnd = outputRange[1]
  const outputLength = outputEnd - outputStart
  return outputStart + (input - inputStart) * (outputLength / inputLength)
}

const getStandardDeviationNumber = <
  Value extends number,
  Aggregate extends Pick<StandardAggregateT, 'mean' | 'std'>,
>(
  value: Value | undefined,
  aggregate: Aggregate | null
) =>
  typeof value !== 'undefined' && aggregate
    ? aggregate.std === 0
      ? 0
      : (value - aggregate.mean) / aggregate.std
    : undefined

const getImpact = <T, U>(
  metric: T,
  aggregate: U,
  values: {
    getValue: (metric: T) => number | undefined
    getAggregateValue: (aggregate: U) => StandardAggregateT | null
    weight?: number
    downIsGood?: boolean
  }[],
  scale: {
    getValue: (metric: T) => number | undefined
    getAggregateValue: (aggregate: U) => StandardAggregateT | null
  }
) => {
  const scaleValue = scale.getValue(metric)
  const scaleAggregateValue = scale.getAggregateValue(aggregate)
  if (typeof scaleValue === 'undefined' || scaleAggregateValue === null) return undefined

  const scoreValues = values
    .map((value) => ({
      ...value,
      standardDeviationNumber: getStandardDeviationNumber(
        value.getValue(metric),
        value.getAggregateValue(aggregate)
      ),
    }))
    .flatMap((value) =>
      typeof value.standardDeviationNumber !== 'undefined'
        ? new Array(value.weight ?? 1).fill(
            value.standardDeviationNumber * (value.downIsGood ? -1 : 1)
          )
        : undefined
    )
    .filter(isNotNullable)
  if (scoreValues.length === 0) return undefined
  const scoreValue = mean(scoreValues)

  // TODO: figure out what scale we should use. Maybe the std deviation number?
  const scaleBy = linearInterpolate(scaleValue, [0, scaleAggregateValue.max], [0, 1])
  const impact = clamp(linearInterpolate(scoreValue * scaleBy, [-2, 2], [-5, 5]), -5, 5)
  return impact
}

export default {
  isNotNullable,
  aggregateMetricValues,
  groupAndAggregate,
  getStandardAggregate,
  getStandardBooleanAggregate,
  getRequestMetrics,
  getStoreMetrics,
  getUserMetrics,
  getCreatorMetrics,
  getPurchaserMetrics,
  getInsertedAtDateMetrics,
  getMachineMetrics,
  getUrgencyMetrics,
  getCategoryMetrics,
  getStoreInsertedAtDateMetrics,
  getGroupedInsertedAtDateMetrics,
  getChartData,
  aggregateFirstDegreeMetrics,
  aggregateSecondDegreeMetrics,
  linearInterpolate,
  getStandardDeviationNumber,
  getImpact,
}
