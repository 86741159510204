import { CheckCircleIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'

import { Money } from '@/types'
import MoneyM from '../../gf/modules/Money'
import useGqlClient from '../hooks/useGqlClient'

import Action from '@/gf/components/Action'
import { partsHubPlans, Plan as PlanT, PlanType } from '@/retail/components/PricingSection'
import A from '../../gf/components/A'
import FixedPlusModal from './FixedPlusModal'
import FreeProTrialModal from './FreeProTrialModal'

type Plan = PlanT & {
  isCurrentPlan?: boolean
  isDowngrade?: boolean
  isFreeTrial?: boolean
  ctaOnClick?: () => void
}

const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className="flex-shrink-0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0484 6.35159C20.2734 6.57662 20.3997 6.88179 20.3997 7.19999C20.3997 7.51818 20.2734 7.82335 20.0484 8.04839L10.4484 17.6484C10.2234 17.8734 9.9182 17.9997 9.6 17.9997C9.2818 17.9997 8.97663 17.8734 8.7516 17.6484L3.9516 12.8484C3.73301 12.6221 3.61206 12.3189 3.61479 12.0043C3.61753 11.6897 3.74373 11.3887 3.96622 11.1662C4.18871 10.9437 4.48968 10.8175 4.80432 10.8148C5.11895 10.812 5.42208 10.933 5.6484 11.1516L9.6 15.1032L18.3516 6.35159C18.5766 6.12662 18.8818 6.00024 19.2 6.00024C19.5182 6.00024 19.8234 6.12662 20.0484 6.35159Z"
      fill="#111827"
    />
  </svg>
)

const CTA = ({
  primary,
  text,
  action,
}: {
  primary: boolean
  text: string
  action: { href: string } | { onClick: () => void }
}) => {
  const className = 'w-full lg:py-3 text-sm lg:text-base mt-4 sm:mt-8 text-center'
  const Component = 'href' in action ? (primary ? A.P : A.S) : primary ? Action.P : Action.S
  return 'href' in action ? (
    <Component className={className} href={action.href}>
      {text}
    </Component>
  ) : (
    <Component className={className} onClick={action.onClick}>
      {text}
    </Component>
  )
}

const Plan = ({
  pretext,
  posttext,
  showFee,
  isMostPopular = false,
  isCurrentPlan = false,
  isDowngrade = false,
  isFreeTrial = false,
  name,
  desc,
  highlights,
  price,
  priceLabel,
  ctaText,
  ctaHref,
  ctaOnClick,
}: Plan) => (
  <div
    className={classNames(
      'rounded-xl xl:rounded-[1.25rem] shadow-lg flex flex-col flex-shrink-0 border',
      isMostPopular ? 'xl:w-[28.125rem] bg-blue-500 p-[0.3125rem]' : 'xl:w-[22.9375rem]',
      isCurrentPlan && 'outline outline-3 -outline-offset-1 outline-blue-600'
    )}
  >
    {isMostPopular && (
      <p className="text-center text-white font-medium text-xl pt-[0.125rem] pb-[0.4375rem]">
        Most Popular
      </p>
    )}
    <div className="flex-grow flex flex-col p-4 sm:p-8 bg-white rounded-xl xl:rounded-[1.25rem]">
      <hgroup className="space-y-2 sm:space-y-4">
        <h3 className="text-3xl sm:text-5xl font-medium leading-[2.5rem] sm:leading-[3.25rem]">
          {name}
        </h3>
        <p className="text-sm sm:text-base">{desc}</p>
      </hgroup>
      <ul className="mt-4 sm:mt-8 space-y-2 sm:space-y-6 flex-grow">
        {highlights.map((text) => (
          <li key={text} className="flex gap-x-2 sm:gap-x-4 text-sm sm:text-base">
            <CheckIcon /> {text}
          </li>
        ))}
      </ul>
      <div className="pt-6">
        {pretext && <p className="text-xs sm:text-sm">{pretext}</p>}
        <p className="font-medium text-xl sm:text-3xl">
          {price}{' '}
          {priceLabel && <span className="text-sm md:text-base font-normal">{priceLabel}</span>}
        </p>
        {showFee && <p className="text-sm sm:text-base mt-1 sm:mt-2">+3% transaction fee</p>}
        {posttext && <p className="text-sm sm:text-base mt-1 sm:mt-2">{posttext}</p>}
      </div>
      {isCurrentPlan ? (
        isFreeTrial ? (
          <div className="w-full py-0 text-sm lg:text-base mt-4 sm:mt-5 text-center inline-flex flex-col justify-center items-center gap-y-2 sm:gap-y-3">
            <span className="inline-flex justify-center items-center gap-x-1.5 lg:text-base text-blue-700">
              <CheckCircleIcon className="w-6 h-6" />
              Current Plan (free trial)
            </span>
            <CTA
              primary
              action={ctaOnClick ? { onClick: ctaOnClick } : { href: ctaHref }}
              text="Upgrade Now"
            />
          </div>
        ) : (
          <div className="w-full lg:py-3 text-sm lg:text-base mt-4 sm:mt-8 text-center inline-flex justify-center items-center gap-x-1.5 text-blue-700">
            <CheckCircleIcon className="w-6 h-6" />
            Current Plan
          </div>
        )
      ) : (
        <CTA
          primary={!isDowngrade}
          action={ctaOnClick ? { onClick: ctaOnClick } : { href: ctaHref }}
          text={ctaText}
        />
      )}
    </div>
  </div>
)

const PricingSection = ({ className, plans }: { className?: string; plans: Plan[] }) => (
  <div className={className}>
    <div className="flex flex-col xl:flex-row xl:items-stretch xl:justify-center gap-8">
      {plans.map((p) => (
        <Plan key={p.name} {...p} isMostPopular={false} />
      ))}
    </div>
  </div>
)

const PricingOptions = ({
  currentPlanId,
  currentPlanPricing,
  refetch,
  isFreeTrial,
}: {
  currentPlanId: PlanType
  currentPlanPricing?: {
    frequency: 'monthly' | 'yearly' | 'semi-annual'
    price: Money
    licenseCost: Money | null
    licenses: number
  }
  refetch: () => Promise<unknown>
  isFreeTrial: boolean
}) => {
  const client = useGqlClient()
  const [isFixedPlusModalOpen, setIsFixedPlusModalOpen] = useQueryParam(
    'upgrade',
    withDefault(BooleanParam, false)
  )
  const [isFreeProTrialModalOpen, setIsFreeProTrialModalOpen] = useQueryParam(
    'trial',
    withDefault(BooleanParam, false)
  )
  const plans: Plan[] = partsHubPlans.map((plan) => {
    const isCurrentPlan = plan.id === currentPlanId
    return {
      ...plan,
      isCurrentPlan,
      ...(plan.id < currentPlanId
        ? { ctaText: 'Downgrade', ctaHref: 'mailto:support@gearflow.com', isDowngrade: true }
        : { ctaText: plan.ctaText, ctaHref: plan.ctaHref }),
      isFreeTrial,
      ...(isCurrentPlan && currentPlanPricing && currentPlanPricing.licenses > 0
        ? {
            pretext: undefined,
            price: `${MoneyM.formatNoCents(currentPlanPricing.price)}/${
              currentPlanPricing.frequency === 'yearly'
                ? 'year'
                : currentPlanPricing.frequency === 'semi-annual'
                  ? '6mo'
                  : 'month'
            }`,
            posttext: (
              <>
                Includes {currentPlanPricing.licenses} user{' '}
                {pluralize('license', currentPlanPricing.licenses)}{' '}
                <br className="hidden sm:block" />
                {currentPlanPricing.licenseCost &&
                  currentPlanPricing.frequency !== 'semi-annual' && (
                    <>{MoneyM.formatNoCents(currentPlanPricing.licenseCost)}/mo/add&apos;l user</>
                  )}
                {currentPlanPricing.licenseCost &&
                  currentPlanPricing.frequency === 'semi-annual' && (
                    <>{MoneyM.formatNoCents(currentPlanPricing.licenseCost)}/add&apos;l user</>
                  )}
                {!currentPlanPricing.licenseCost && <>$75/mo/add&apos;l user</>}
              </>
            ),
          }
        : {}),
      // Offer a free trial of Pro
      ...(plan.id === PlanType.Pro && currentPlanId < PlanType.Pro
        ? { ctaOnClick: () => setIsFreeProTrialModalOpen(true) }
        : {}),
      // Offer paid upgrade to pro when they have trial
      ...(isFreeTrial && plan.id === PlanType.Pro && currentPlanId === PlanType.Pro
        ? { ctaOnClick: () => setIsFixedPlusModalOpen(true) }
        : {}),
    }
  })
  return (
    <>
      <FreeProTrialModal
        open={isFreeProTrialModalOpen}
        onClose={() => setIsFreeProTrialModalOpen(false)}
        onClaimed={() => {
          setIsFreeProTrialModalOpen(false)
          return Promise.resolve()
        }}
      />
      <FixedPlusModal
        open={isFixedPlusModalOpen}
        onClose={() => setIsFixedPlusModalOpen(false)}
        onComplete={() =>
          refetch().finally(() => {
            setIsFixedPlusModalOpen(false)
            // Run this at the end, so it doesn't mess up the control logic
            client.refetchQueries({ include: 'all' })
          })
        }
      />
      <PricingSection plans={plans} />
    </>
  )
}

export default PricingOptions
