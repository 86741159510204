import { useEffect, useState } from 'react'
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params'

import {
  AddVendorsMutation,
  SetupDealerLocationsQuery,
  useAddVendorsMutation,
  useDealerLocationsByIdsLazyQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import useMsgs from '@/gf/hooks/useMsgs'
import * as GE from '@/gf/modules/GrammarEvents'
import { Maybe } from '@/types'
import { ApolloError } from '@apollo/client'

type CustomGraphQLError = {
  message: string
  path: Array<string | number>
  fields?: {
    name: string
    value: string[]
  }[]
}
type DealerLocation = SetupDealerLocationsQuery['dealerLocations']['entries'][number] & {
  manuallyAdded?: boolean
}

type Fields = {
  dealerLocations: DealerLocation[]
  search: string
  brandIds: string[]
}

const useVendorsForm = (shippingLocationId: Maybe<string>, accountSetup = false) => {
  const { orgId } = useSession()
  const [_, msgs] = useMsgs()
  const client = useGqlClient()
  const [dealerLocationIds, setDealerLocationIds] = useQueryParam(
    'dId',
    withDefault(ArrayParam, [])
  )
  const [update, { loading: saving }] = useAddVendorsMutation({ client })

  const [fields, setFields] = useState<Fields>({
    dealerLocations: [],
    brandIds: [],
    search: '',
  })

  const updateFields = (updates: Partial<Fields>) => setFields((prev) => ({ ...prev, ...updates }))

  const [fetchDealerLocations] = useDealerLocationsByIdsLazyQuery({ client })

  const initForm = async () => {
    const { data } = await fetchDealerLocations({
      variables: {
        orgId,
        ids: dealerLocationIds,
      },
    })

    updateFields({
      dealerLocations: (data?.dealerLocations.entries ?? []).map((dl) =>
        dl.vendor ? { ...dl, manuallyAdded: true } : dl
      ),
    })
  }

  const dealerLocationIdsLength = dealerLocationIds.length

  useEffect(() => {
    if (dealerLocationIdsLength > 0 && fields.dealerLocations.length === 0) {
      initForm()
    }
  }, [dealerLocationIdsLength, fields.dealerLocations])

  const updateSelectedLocations = (dealerLocations: DealerLocation[]) => {
    updateFields({
      dealerLocations,
    })

    setDealerLocationIds(
      dealerLocations.map(({ id }) => id),
      'replaceIn'
    )
  }

  const toggleLocation = (dealerLocation: DealerLocation, listIndex: number) => {
    const selected = fields.dealerLocations.find((dl) => dl.id === dealerLocation.id)

    const newDealerLocationsList = selected
      ? fields.dealerLocations.filter((dl) => dl.id !== dealerLocation.id)
      : [...fields.dealerLocations, dealerLocation]

    updateSelectedLocations(newDealerLocationsList)

    if (selected) {
      GE.deselectsDealerLocationOnAccountSetup(dealerLocation.id, listIndex)
    } else {
      GE.selectsDealerLocationOnAccountSetup(dealerLocation.id, listIndex)
    }
  }

  const putManuallyAddedVendor = (dealerLocation: DealerLocation) =>
    updateSelectedLocations([...fields.dealerLocations, { ...dealerLocation, manuallyAdded: true }])

  const toggleBrand = (brandId: string) =>
    updateFields({
      brandIds: fields.brandIds.some((bid) => bid === brandId)
        ? fields.brandIds.filter((bid) => bid !== brandId)
        : [...fields.brandIds, brandId],
    })

  const isLocationSelected = (dealerLocation: DealerLocation) =>
    !!fields.dealerLocations.find((dl) => dl.id === dealerLocation.id)

  const isBrandSelected = (brandId: string) => !!fields.brandIds.find((bid) => bid === brandId)

  const onSaveSuccess = ({
    onOk,
    data,
    ids,
  }: {
    onOk?: () => void
    data?: AddVendorsMutation | null
    ids?: string[]
  }) => {
    if (onOk) onOk()

    if (data && ids) {
      data?.addVendors.forEach((vendorId, i) => {
        GE.draftsVendor(vendorId, ids[i])
      })
    }
    if (accountSetup) {
      GE.completesAccountSetup()
      GE.clearFlowId(GE.UserFlow.AccountSetup)
    }
  }

  const save = (onOk?: () => void) => {
    const ids = fields.dealerLocations.filter((dl) => !dl.manuallyAdded).map((dl) => dl.id)

    if (ids.length === 0) {
      onSaveSuccess({ onOk })
    } else {
      update({
        variables: {
          dealerLocationIds: ids,
          shippingLocationId,
        },
      })
        .then(({ data }) => {
          onSaveSuccess({ onOk, data, ids })
        })
        .catch((err: ApolloError) => {
          const graphQLError = err?.graphQLErrors?.[0] as unknown as CustomGraphQLError

          if (graphQLError?.fields && graphQLError?.fields?.length > 0) {
            // If we have field-specific errors, show each field error
            graphQLError.fields.forEach((field) => {
              field.value.forEach((errorMsg: string) => msgs.add(errorMsg, 'negative'))
            })
          } else if (graphQLError?.message) {
            // If we have a message but no fields, show the message
            msgs.add(graphQLError.message, 'negative')
          } else {
            // If we can't find any error information, show unknown error
            msgs.addUnknownError()
          }
        })
    }
  }

  return {
    fields,
    saving,
    updateFields,
    save,
    toggleLocation,
    isLocationSelected,
    putManuallyAddedVendor,
    submitDisabled: false,
    toggleBrand,
    isBrandSelected,
    dealerLocationIds,
  }
}

export default useVendorsForm
