import Link from '@/gf/components/Link'
import { Maybe } from '@/types'
import { List, Row } from '../List'
import { Section } from '../YourOrder'

type MachineInformation = {
  id: string
  name: string
  serialNumber: Maybe<string>
  description?: Maybe<string>
  engineMake?: Maybe<string>
  engineModel?: Maybe<string>
  engineSerialNumber?: Maybe<string>
  machine: {
    make: Maybe<string>
    model: Maybe<string>
    year: Maybe<number>
  }
}

const MachineInformation = ({
  machines,
  allowEdit = false,
  disableLinks = false,
  hideUnitNumber = false,
}: {
  machines: MachineInformation[]
  allowEdit?: boolean
  disableLinks: boolean
  hideUnitNumber?: boolean
}) => (
  <Section className="space-y-2">
    <div className="flex gap-2 items-center">
      <div className="font-medium text-lg text-gray-900">Machine</div>
      {allowEdit && <Link.T to="edit-machine">Edit</Link.T>}
    </div>

    {machines.length === 0 && (
      <div className="italic text-gray-500 text-base">No machine involved</div>
    )}

    <div>
      {machines.map((machine) => (
        <List key={machine.id}>
          <Row>
            <>Equipment Details</>
            <div className="font-medium">
              {machine.machine.year} {machine.machine.make} {machine.machine.model}
            </div>
          </Row>

          {!hideUnitNumber && (
            <Row>
              <>Unit Number</>
              {disableLinks ? (
                machine.name
              ) : (
                <Link.T to={`/machines/${machine.id}`} key={machine.id}>
                  {machine.name}
                </Link.T>
              )}
            </Row>
          )}

          <Row>
            <>Serial Number</>
            <>{machine.serialNumber}</>
          </Row>

          {machine.description && (
            <Row>
              <>Description</>
              <>{machine.description}</>
            </Row>
          )}

          {(machine.engineMake || machine.engineModel) && (
            <Row>
              <>Engine Details</>
              <>{`${machine.engineMake || ''} ${machine.engineModel || ''}`}</>
            </Row>
          )}

          {machine.engineSerialNumber && (
            <Row>
              <>Engine Serial Number</>
              <>{machine.engineSerialNumber}</>
            </Row>
          )}
        </List>
      ))}
    </div>
  </Section>
)

export default MachineInformation
