import { InvoiceV2 } from '@/buyers/_gen/gql'
import Box from '@/buyers/components/Box'
import { useOrderContext } from '../context'
import ContactVendorCard from './ContactVendorCard'
import InvoiceUpload from './InvoiceUpload'

const ContactVendor = () => {
  const { storeOrder, refetchStoreOrder } = useOrderContext()

  const invoice = storeOrder.invoices?.[0]
  const invoiceSucceeded = invoice?.processingStatus === 'succeeded'

  return (
    <Box className="text-center space-y-2 flex flex-col items-center" dim>
      {storeOrder.quickOrder && !invoiceSucceeded && (
        <>
          <div className="text-lg font-medium">Check on price and availability</div>
          <div className="text-base">
            Call or message your vendor to confirm the price and availability. You can also upload
            the invoice once received.
          </div>
        </>
      )}

      {!storeOrder.quickOrder && (
        <>
          <div className="text-lg font-medium">Have Questions?</div>
          <div className="text-base">
            Quickly reach out to {storeOrder.store.name} by messaging them here, and they'll be
            notified immediately.
          </div>
        </>
      )}

      <div className="pt-2 w-full space-y-4 flex flex-col">
        <ContactVendorCard />
        {storeOrder.quickOrder && (
          <InvoiceUpload
            invoice={invoice as InvoiceV2}
            onUploadComplete={() => refetchStoreOrder()}
          />
        )}
      </div>
    </Box>
  )
}

export default ContactVendor
