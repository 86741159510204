import { useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import {
  useExternalOrderSearchVendorsQuery,
  useExternalOrderSelectedVendorQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Select from '@/gf/components/next/Select'

const ExternalVendors = ({
  value,
  onChange,
}: {
  value: string | null
  onChange: (newValue: string) => void
}) => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const [search, setSearch] = useState('')

  const { data, loading } = useExternalOrderSearchVendorsQuery({
    client,
    variables: { orgId, search },
  })

  const { data: selectedData, loading: loadingSelected } = useExternalOrderSelectedVendorQuery({
    client,
    variables: { id: value! },
    skip: !value,
  })

  const options = useMemo(
    () =>
      data?.org?.paginatedVendors.vendors.map((v) => ({
        value: v.id,
        label: v.name,
      })) ?? [],
    [data]
  )

  const selectedOption = useMemo(
    () =>
      value
        ? {
            value,
            label: selectedData?.vendor?.name ?? '',
          }
        : null,
    [selectedData, value]
  )

  const debouncedSetSearch = useRef(debounce(setSearch, 400)).current

  return (
    <Select
      isLoading={loading || loadingSelected}
      options={options}
      value={selectedOption}
      onInputChange={(input) => debouncedSetSearch(input)}
      onChange={(o) => (o ? onChange(o.value) : undefined)}
      placeholder="Select a vendor..."
      className="text-sm w-full max-w-xs"
    />
  )
}

export default ExternalVendors
