import {
  UserLocationsDocument as Query,
  useExternalOrderSelectedLocationQuery,
  useUserLocationsQuery,
} from '@/buyers/_gen/gql'
import useGqlClient from '@/buyers/hooks/useGqlClient'
import useSession from '@/buyers/hooks/useSession'
import Select from '@/gf/components/next/Select'
import { Maybe } from '@/types'
import { useMemo } from 'react'

export { Query }

const LocationSelector = ({
  value,
  onChange,
}: {
  value: Maybe<string>
  onChange: (newValue: Maybe<string>) => void
}) => {
  const { orgId } = useSession()

  const client = useGqlClient()
  const { data, loading } = useUserLocationsQuery({
    variables: { orgId, search: null },
    client,
  })

  const { data: selectedData, loading: loadingSelected } = useExternalOrderSelectedLocationQuery({
    variables: { orgId, id: value! },
    client,
    skip: !value,
  })

  const userLocations = data?.org?.locations ?? []

  const locationName = (l) => `${l.name} ${l.defaultLocation ? `(default location)` : ''}`

  const options = useMemo(() => {
    const userLocationsOptions = userLocations
      .sort((a, _b) => (a.defaultLocation ? -1 : 0)) // default location first
      .map((l) => ({
        label: locationName(l),
        value: l.id,
      }))

    return userLocationsOptions
  }, [userLocations])

  const selectedOption = useMemo(
    () =>
      value
        ? {
            value: value,
            label: selectedData
              ? locationName(selectedData.shippingLocationsSearch.shippingLocations[0])
              : null,
          }
        : null,
    [value, selectedData]
  )

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={(o) => (o ? onChange(o.value) : undefined)}
      isLoading={loading || loadingSelected}
      className="w-full max-w-xs"
    />
  )
}

export default LocationSelector
