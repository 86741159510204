import useGqlClient from '@/buyers/hooks/useGqlClient'
import Modal from '@/gf/components/ModalNext'
import useSession from '@/buyers/hooks/useSession'
import { useCallback, useState } from 'react'
import { ModalSize } from '@/types'
import SearchInput from '@/gf/components/SearchInput'
import Pagination from '@/gf/components/Pagination'
import usePage from '@/gf/hooks/usePage'
import {
  ListManualsDocument,
  PartsManualMachinesDocument,
  PartsManualMachinesQuery,
  useLinkOrgMachinesToManualMutation,
  usePartsManualMachinesQuery,
  usePartsManualOrgMachineSearchQuery,
} from '@/buyers/_gen/gql'
import Checkbox from '@/gf/components/next/forms/Checkbox'
import TextTag from '@/gf/components/next/TextTag'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Action from '@/gf/components/Action'
import useMsgs from '@/gf/hooks/useMsgs'

const AssignOrgMachinesModal = ({
  partsManualId,
  open,
  onClose,
}: {
  partsManualId: string
  open: boolean
  onClose: () => void
}) => {
  const client = useGqlClient()
  const { orgId } = useSession()
  const [_, msgs] = useMsgs()
  const [page, setPage] = usePage()
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState<PartsManualMachinesQuery['manual']['orgMachines']>([])
  const [linkOrgMachines, { loading: linking }] = useLinkOrgMachinesToManualMutation({
    client,
    refetchQueries: [ListManualsDocument, PartsManualMachinesDocument],
  })
  usePartsManualMachinesQuery({
    client,
    variables: { id: partsManualId, orgId },
    onCompleted: (data) => {
      setSelected(data.manual.orgMachines)
    },
  })

  const { data } = usePartsManualOrgMachineSearchQuery({
    client,
    variables: {
      orgId,
      page,
      search,
      filters: [['equals', 'accountId', orgId]],
    },
  })

  const onSearchChanged = (searchTerm: string) => {
    setPage(1)
    setSearch(searchTerm)
  }

  const isSelected = useCallback(
    (orgMachineId: string) => selected.some((s) => s.id === orgMachineId),
    [selected]
  )

  const toggleSelection = useCallback(
    (orgMachine: PartsManualMachinesQuery['manual']['orgMachines'][number]) => {
      setSelected((prev) =>
        isSelected(orgMachine.id)
          ? prev.filter((om) => om.id !== orgMachine.id)
          : [...prev, orgMachine]
      )
    },
    [selected, isSelected]
  )
  const onSubmit = useCallback(async () => {
    try {
      linkOrgMachines({ variables: { partsManualId, orgMachineIds: selected.map(({ id }) => id) } })

      onClose()

      msgs.add('Linked Machines to Manual!', 'positive')
    } catch (err) {
      console.error(err)

      msgs.addUnknownError()
    }
  }, [selected])

  return (
    <Modal open={open} onClose={onClose} size={ModalSize.MD}>
      <div className="relative">
        <CloseModalButton className="absolute top-3 right-3" onClick={onClose} />

        <div className="p-6 space-y-4">
          <hgroup>
            <h3 className="text-2xl font-medium">Link Equipment to Parts Manual</h3>
            <p className="text-lg">
              Enable mechanics to access diagrams and parts directly from their mobile app by
              linking parts manuals to machines in your fleet
            </p>
          </hgroup>
          <div className="flex flex-wrap gap-1.5">
            {selected.map((om) => (
              <TextTag color="blue" key={om.id} onRemoveClick={() => toggleSelection(om)}>
                {om.name} - {om.machine.make} {om.machine.model} {om.machine.year}
              </TextTag>
            ))}
          </div>

          <SearchInput
            value={search}
            onChange={onSearchChanged}
            placeholder="Search by unit number, model or serial number"
            autoCompleteOff
          />
          <ul className="h-90 overflow-y-auto px-1">
            {data?.paginatedOrgMachines.machines.map((om) => (
              <li className="block w-full" key={om.id}>
                <button
                  type="button"
                  className="flex border-b py-2 gap-x-2 hover:bg-gray-50 px-1 w-full cursor-pointer"
                  onClick={() => toggleSelection(om)}
                >
                  <div className="mt-1">
                    <Checkbox checked={isSelected(om.id)} onChange={() => toggleSelection(om)} />
                  </div>
                  <span className="flex flex-col">
                    <span className="font-medium flex gap-x-4 text-base">
                      <span className="text-base">{om.name}</span>

                      {om.serialNumber && (
                        <span className="text-sm">
                          <span className="italic text-gray-600">S/N:</span> {om.serialNumber}
                        </span>
                      )}
                    </span>
                    <span className="font-normal">
                      {om.machine.make} {om.machine.model} {om.machine.year}
                    </span>
                  </span>
                </button>
              </li>
            ))}
          </ul>

          <Pagination
            pagination={data?.paginatedOrgMachines.pagination}
            page={page}
            updatePage={setPage}
          />

          <div className="flex justify-end">
            <Action.P onClick={() => onSubmit()} size="lg" performing={linking}>
              Save
            </Action.P>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AssignOrgMachinesModal
